import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class Service575Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="575"
          description="View route information and buy your ticket for the 575 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">575 service</h1>
              <p>Wood Lane / Manor Road - Rodillian Academy</p>
              <p>Rodillian Academy - Wood Lane at Manor Road</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="575 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1jaFSM5m7StTUJlzXrUag005aB5_vOFw&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Rodillian Academy
                </p>
                <p>
                  From Wood Lane, Wakefield Road, Leeds Road, Long Thorpe Lane.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="575 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1A7nse7ISz_aZix2LNeINL7teNx_ssqo&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Rodillian Academy
                </p>
                <p>
                  From Long Thorpe Lane, Leeds Road, Wakefield Road, Wood Lane, Haigh Road, Styebank Lane, Leeds Road, Holmsley Lane, Church Street.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Wood Lane / Manor Road</td>
                      <td>DEP</td>
                      <td>0800</td>
                    </tr>
                    <tr>
                      <td>Wakefield Road at Glebe Farm</td>
                      <td>DEP</td>
                      <td>0805</td>
                    </tr>
                    <tr>
                      <td>Halfway House</td>
                      <td>DEP</td>
                      <td>0810</td>
                    </tr>
                    <tr>
                      <td>Rodillian Academy</td>
                      <td>ARR</td>
                      <td>0815</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Rodillian Academy</td>
                      <td>DEP</td>
                      <td>1440</td>
                    </tr>
                    <tr>
                      <td>Robin Hood</td>
                      <td>DEP</td>
                      <td>1443</td>
                    </tr>
                    <tr>
                      <td>Wakefield Road at Glebe Farm</td>
                      <td>DEP</td>
                      <td>1446</td>
                    </tr>
                    <tr>
                      <td>Haigh Side</td>
                      <td>DEP</td>
                      <td>1448</td>
                    </tr>
                    <tr>
                      <td>Church Street</td>
                      <td>ARR</td>
                      <td>1456</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default Service575Page